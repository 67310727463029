import ProductService from "../../services/productService";

const state = {
    loading: true,
    products: [],
    priceOverrides: [],
};

const getters = {
    allProducts: (state) => state.products,
    allPriceOverrides: (state) => state.priceOverrides,
    productIsLoading: (state) => state.loading,
};

const actions = {
    async resetProductState({commit}) {
        await commit("RESET_PRODUCT_STATE");
    },
    async productLoading({commit}, payload) {
        await commit("PRODUCT_LOADING", payload);
    },
    async fetchProducts({commit}) {
        const serverResponse = await ProductService.getAllProducts();
        await commit("SET_PRODUCTS", serverResponse.data.data);
    },
    async fetchProductsForCategory({commit}, categoryId) {
        const serverResponse = await ProductService.getAllProductsByCategory(
            categoryId
        );
        await commit("SET_PRODUCTS", serverResponse.data.data);
    },
    async createProduct({commit}, formData) {
        const serverResponse = await ProductService.createProduct(formData);
    },
    async updateProduct({commit}, formData) {
        const serverResponse = await ProductService.updateProduct(formData);
    },
    async updateStocks({commit}, formData) {
        const serverResponse = await ProductService.updateStocks(formData);
    },

    async deleteProduct({commit}, id) {
        await ProductService.deleteProduct(id);
        commit("removeProduct", id);
    },

    async toggleVisibility({commit}, id) {
        await ProductService.toggleVisibility(id);
        const product = state.products.find((product) => product.id === id);
    },
    async getProduct({commit}, id) {
        const serverResponse = await ProductService.getProduct(id);
        return await serverResponse.data.data;
    },

    // Product price overrides
    async overrideProductPriceByWarehouse({commit}, payload) {
        await ProductService.overrideProductPriceByWarehouse(payload);
    },

    async getProductPriceOverridesByWarehouse({commit}, productId) {
        const serverResponse = await ProductService.getProductPriceOverridesByWarehouse(productId);
        await commit("SET_PRICE_OVERRIDES", serverResponse.data);

    },

    async removeOverrideProductPriceByWarehouse({commit}, payload) {
        await ProductService.removeOverrideProductPriceByWarehouse(payload);
    },
};

const mutations = {
    RESET_PRODUCT_STATE: (state) => Object.assign(state, defaultState()),
    PRODUCT_LOADING: (state, payload) => (state.loading = payload),
    SET_PRODUCTS: (state, products) => (state.products = products),
    SET_PRICE_OVERRIDES: (state, priceOverrides) => (state.priceOverrides = priceOverrides),
    removeProduct: (state, id) =>
        (state.products = state.products.filter((product) => product.id !== id)),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
