import ApiClient from "@/libs/apiClient";

export default {
    getAllProductsByCategory(categoryId) {
        if (!categoryId) {
            return ApiClient.get("products");
        }
        return ApiClient.get("products", {
            params: {
                category_id: categoryId,
            },
        });
    },
    getAllProducts() {
        return ApiClient.get("products");
    },
    deleteProduct(id) {
        return ApiClient.delete(`products/${id}`);
    },

    toggleVisibility(id) {
        return ApiClient.put(`products/${id}/visibility`);
    },
    createProduct(formData) {
        return ApiClient.post("products", formData);
    },
    updateProduct(formData, id) {
        return ApiClient.put(`products/${formData.id}`, formData);
    },
    updateStocks(formData) {
        return ApiClient.put(`products/${formData.id}/stocks`, formData);
    },
    getProduct(id) {
        return ApiClient.get(`products/${id}`);
    },

    getProductPriceOverridesByWarehouse(productId) {
        return ApiClient.get(`product-price-to-warehouse/${productId}`);
    },

    overrideProductPriceByWarehouse(payload) {
        return ApiClient.post(`product-price-to-warehouse`, payload);
    },

    removeOverrideProductPriceByWarehouse(payload) {
        return ApiClient.post(`product-price-to-warehouse/delete`, payload);
    },
};
